import { React } from 'myedDependenciesReact';
import { reportError } from 'MyEdUtilsCommon';
import LoginPage from '../../LoginPage';
import AppContainer from '../../AppContainer';

export default class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  async componentDidCatch(error, info) {
    this.setState({ hasError: true });
    reportError(error, 'APP', info);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <LoginPage disabled={hasError} />;
    }
    return <AppContainer />;
  }
}
