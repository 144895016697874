/* globals window, PRODUCTION_BUILD */
import { React, HashRouter as Router, PropTypes } from 'myedDependenciesReact';
import { Analytics, initialize } from 'MyEdGoogleAnalyticsMyEdProgressive';
import { fetchUserInfo } from 'MyEdDataLayer';
import { EmergencyAlertContainer } from 'MyEdUtilsCommon';
import Channel from './components/navigation/Channel';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import NotSupportedPortlet from './components/portlets/NotSupportedPortlet';
import Portlet from './components/portlets/Portlet';
import RouterView from './components/navigation/RouterView';

const propTypes = {
  layout: PropTypes.shape({
    sortedRegions: PropTypes.shape({
      'header-bottom': PropTypes.shape({}),
      customize: PropTypes.shape({}),
      mezzanine: PropTypes.shape({}),
      'sidebar-left': PropTypes.shape({}),
      'pre-content': PropTypes.shape({}),
      content: PropTypes.shape({}),
      'post-content': PropTypes.shape({}),
    }).isRequired,
    payload: PropTypes.shape({
      layout: PropTypes.shape({
        navigation: PropTypes.shape({}),
      }),
      authenticated: PropTypes.string,
    }).isRequired,
  }).isRequired,
  pending: PropTypes.bool,
};

const defaultProps = {
  pending: false,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.limit = 1800000;
    this.state = {
      start: null,
    };
  }

  async componentDidMount() {
    if (PRODUCTION_BUILD) {
      window.addEventListener('focus', this.onWindowFocus);
    }
    await fetchUserInfo();
    await initialize();
    this.setState({
      start: Date.now(),
    });
  }

  componentWillUnmount() {
    if (PRODUCTION_BUILD) {
      window.removeEventListener('focus', this.onWindowFocus);
    }
  }

  onWindowFocus = () => {
    const {
      pathname,
      search,
      hash,
      hostname,
    } = window.location;
    const { start } = this.state;
    const currentUrl = encodeURIComponent(
      `${pathname}${search}${hash}`,
    );
    const duration = Date.now() - start;
    if (duration > this.limit) {
      window.location = `https://${hostname}/uPortal/Login?refUrl=${currentUrl}`;
    }
  };

  render() {
    const { layout, pending } = this.props;
    const payload = layout.payload || {};
    const loginClass = payload.authenticated === 'true' ? 'logged-in-user' : 'not-logged-in';
    if (!pending) {
      return (
        <Router>
          <div className={loginClass}>
            <Analytics category="MyEdProgressive">
              <a className="sr-only sr-only-focusable" href="#portalPageBody">Skip to page content</a>
            </Analytics>
            <Header
              role="banner"
              regions={layout.sortedRegions}
            >
              <div id="region-header-bottom-myed">
                <div id="app-myed-navigation">
                  <Channel
                    navigationRegion={layout.sortedRegions['header-bottom']}
                    fname="myed-navigation"
                    hasFrame={false}
                  />
                </div>
              </div>
            </Header>
            <div className="container content">
              <Portlet payload={layout.sortedRegions.customize} />
              <Portlet payload={layout.sortedRegions.mezzanine} />
              <Portlet
                payload={layout.sortedRegions['sidebar-left']}
              />
              <NotSupportedPortlet
                config={layout.sortedRegions['pre-content']}
              />
            </div>
            <div id="portalPageBody" />
            <div className="container-fluid content">
              <EmergencyAlertContainer />
            </div>
            <RouterView
              navigation={layout.payload.layout.navigation}
              authenticated={layout.payload.authenticated}
            />
            <div className="container content">
              <div className="row">
                <div className="col-md-12">
                  <Portlet payload={layout.sortedRegions.content} />
                  <Portlet
                    payload={layout.sortedRegions['post-content']}
                  />
                </div>
              </div>
            </div>
            <Footer layout={layout} />
          </div>
        </Router>
      );
    }
    return null;
  }
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
