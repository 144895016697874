import {
  React,
  PropTypes,
  useSearchParams,
} from 'myedDependenciesReact';
import { reduxConnect, fetchSearchResults } from 'MyEdDataLayer';
import SearchPage from './SearchPage';

const { useEffect } = React;

const propTypes = {
  configuration: PropTypes.shape({}).isRequired,
  search: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})),
    query: PropTypes.string.isRequired,
  }).isRequired,
  dispatchFetchSearchResults: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { configuration, search } = state;

  return {
    configuration,
    search,
  };
}

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchSearchResults:
    (query) => dispatch(fetchSearchResults(query)),
});

function SearchPageContainer({ configuration, search, dispatchFetchSearchResults }) {
  const [searchParams] = useSearchParams();
  const decodedQuery = decodeURIComponent(searchParams.get('query'));

  useEffect(() => {
    if (search.query !== decodedQuery) {
      dispatchFetchSearchResults(decodedQuery).unwrap().catch((e) => {
        console.warn(e);
      });
    }
  });

  return (
    <SearchPage
      query={decodedQuery}
      configuration={configuration}
      search={search}
    />
  );
}

SearchPageContainer.propTypes = propTypes;

export default reduxConnect({ mapStateToProps, mapDispatchToProps }, SearchPageContainer);
