import { React, PropTypes } from 'myedDependenciesReact';
import { PortletErrorBoundary } from 'MyEdUtilsCommon';
import EditablePortlet from '../portlets/EditablePortlet';
import changeDocumentTitle from '../../utils/changeDocumentTitle';

function CmsFullPage(props) {
  const {
    layout,
    layoutLocation,
    contentLocation,
    portletLocation,
    id,
  } = props;
  const content = layout.payload.layout.navigation.tabs[layoutLocation].content[contentLocation];
  const externalId = content.externalId !== undefined ? `${content.externalId}` : `${content.ID}`;
  const isAdmin = externalId === 'admin';
  const portlet = content.content[portletLocation];
  if (portlet.ID === id) {
    const config = {
      content: [portlet],
      name: portlet.name,
    };
    changeDocumentTitle(portlet.name);
    // As we're in full-page mode, ensure the portlet header gets focus on mounting
    const hasFocus = true;
    return (
      <section
        className="mainContent"
        role="main"
        tabIndex="-1"
      >
        <div className="page container-fluid content">
          <div className="row">
            <div className="col-md-12">
              <PortletErrorBoundary key={portlet.ID} componentId={portlet.chanID}>
                <EditablePortlet
                  key={portlet.ID}
                  payload={config}
                  isAdmin={isAdmin}
                  hasFocus={hasFocus}
                  componentId={portlet.chanID}
                />
              </PortletErrorBoundary>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return null;
}

CmsFullPage.propTypes = {
  layout: PropTypes.shape({
    payload: PropTypes.shape({
      layout: PropTypes.shape({
        navigation: PropTypes.shape({
          // eslint-disable-next-line react/forbid-prop-types
          tabs: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }),
  }).isRequired,
  layoutLocation: PropTypes.string.isRequired,
  contentLocation: PropTypes.string.isRequired,
  portletLocation: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

CmsFullPage.defaultProps = {};

export default CmsFullPage;
