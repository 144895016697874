/* global document */
import { FetchService, reduxConnect } from 'MyEdDataLayer';
import { PortletCMSAnalytics } from 'MyEdGoogleAnalyticsMyEdProgressive';
import { React, PropTypes } from 'myedDependenciesReact';
import UportalLibrariesLoader from '../../utils/UportalLibrariesLoader';

const propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  uportalBase: PropTypes.string,
  edPrimaryAffiliation: PropTypes.arrayOf(PropTypes.string),
  serverEnvironment: PropTypes.string,
};

const defaultProps = {
  uportalBase: '',
  edPrimaryAffiliation: [],
  serverEnvironment: '',
};

function mapStateToProps(state) {
  const { url } = state.configuration.uportal;
  const { edPrimaryAffiliation, serverEnvironment } = state.userDetails;

  return {
    uportalBase: url,
    edPrimaryAffiliation,
    serverEnvironment,
  };
}

class LegacyLibraries extends React.Component {
  static fetchContent(uportalBase, url) {
    return FetchService.fetchRaw(
      `${uportalBase}${url}`,
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      analytics: null,
    };
    this._ref = React.createRef();
  }

  componentDidMount() {
    if (this._ref.current) {
      this.generateHtml(this._ref.current);
    }
  }

  componentWillUnmount() {
    const { analytics } = this.state;
    analytics.removeEvents();
    this._ref.current.parentElement.removeChild(this._ref.current);
  }

  injectScripts(refCurrent, div) {
    const { name } = this.props;
    const scripts = [];
    this._generateScripts(refCurrent, scripts, div);
    scripts.forEach((scriptElement) => {
      refCurrent.appendChild(scriptElement);
    });
    const analytics = new PortletCMSAnalytics(name, refCurrent);
    analytics.applyEvents();
    this.setState({
      analytics,
    });
  }

  async generateHtml(refCurrent) {
    const { uportalBase, url, id } = this.props;
    const payload = await LegacyLibraries.fetchContent(uportalBase, url);
    const div = document.createElement('div');
    refCurrent.setAttribute('id', `component-${id}`);
    div.innerHTML = payload.text;
    await UportalLibrariesLoader.load();
    refCurrent.appendChild(div);
    this.injectScripts(refCurrent, div);
  }

  _generateScripts(refCurrent, scripts, targetElement, parentElement) {
    if (targetElement.nodeName === 'SCRIPT') {
      const scriptElem = document.createElement('script');
      if (targetElement.src !== undefined && targetElement.src !== '') {
        scriptElem.src = targetElement.src;
      }
      if (targetElement.text !== undefined) {
        scriptElem.text = ` try {${targetElement.text}}catch (error) {
            window.reportLegacyError(error, '${refCurrent.id}')
            ;}`;
      }
      scripts.push(scriptElem);

      targetElement.parentElement.removeChild(targetElement);
    } else if (targetElement.hasChildNodes()) {
      const children = targetElement.childNodes;

      for (let i = 0; i < children.length; i += 1) {
        this._generateScripts(refCurrent, scripts, children[i], targetElement);
      }
    }
    if (parentElement === undefined) {
      return targetElement;
    }
    return parentElement;
  }

  render() {
    const { edPrimaryAffiliation, serverEnvironment } = this.props;
    if (edPrimaryAffiliation && edPrimaryAffiliation.length) {
      const classes = `myed-affiliation-${edPrimaryAffiliation.sort().join('-')} myed-env-${serverEnvironment}`;
      return (
        <div
          className={classes}
          ref={this._ref}
        />
      );
    }

    // Allow content to show for users who are not logged in, i.e. when edPrimaryAffiliation is null
    return (
      <div
        ref={this._ref}
      />
    );
  }
}

LegacyLibraries.propTypes = propTypes;
LegacyLibraries.defaultProps = defaultProps;

export default reduxConnect(mapStateToProps, LegacyLibraries);
