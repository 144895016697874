/* globals window, document */
import { React, ReactDOMClient } from 'myedDependenciesReact';
import { MessageCenterContainer, reportError } from 'MyEdUtilsCommon';

window.reportLegacyError = (error, targetID) => {
  const targetElement = document.getElementById(targetID);
  const legacyTargetId = `legacy-portlet-${targetID}`;
  reportError(error, legacyTargetId);

  const root = ReactDOMClient.createRoot(targetElement);
  root.render(<MessageCenterContainer componentID={legacyTargetId} />);

  throw error;
};
