import { React, PropTypes } from 'myedDependenciesReact';
import { PortletErrorBoundary } from 'MyEdUtilsCommon';
import Portlet from './Portlet';

function EditablePortlet(props) {
  const {
    payload, isAdmin, settings, hasFrame, hasFocus, componentId,
  } = props;
  if (payload.name !== 'Missing channel') {
    return (
      <section aria-label={payload.content[0].description}>
        <PortletErrorBoundary payload={payload} componentId={componentId}>
          <Portlet
            payload={payload}
            isAdmin={isAdmin}
            settings={settings}
            hasFrame={hasFrame}
            hasFocus={hasFocus}
            showMenu
          />
        </PortletErrorBoundary>
      </section>
    );
  }
  return null;
}

EditablePortlet.propTypes = {
  payload: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string.isRequired,
    })),
    name: PropTypes.string,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  settings: PropTypes.shape({}),
  hasFrame: PropTypes.bool,
  hasFocus: PropTypes.bool,
  componentId: PropTypes.string.isRequired,
};

EditablePortlet.defaultProps = {
  hasFrame: true,
  hasFocus: false,
  settings: undefined,
};

export default EditablePortlet;
