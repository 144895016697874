/* globals document */
import { React, ReactDOMClient } from 'myedDependenciesReact';
import {
  store,
  wrapWithProvider,
  fetchStructure,
  addCriticalMessage,
} from 'MyEdDataLayer';
import './main.scss';
import AppErrorBoundary from './components/error-handling/AppErrorBoundary';
import LoginPage from './LoginPage';
import './components/error-handling/ReportLegacyError';
import UportalLibrariesLoader from './utils/UportalLibrariesLoader';
import './styles/edgel.less';

UportalLibrariesLoader.load().then(() => {
  const root = ReactDOMClient.createRoot(document.getElementById('root'));

  fetchStructure().then(async () => {
    const { messaging } = store.getState();
    if (messaging !== undefined && messaging.disableApp) {
      await store.dispatch(addCriticalMessage());
      root.render(wrapWithProvider(<LoginPage disabled={messaging.disableApp} />));
    } else {
      root.render(wrapWithProvider(<AppErrorBoundary />));
    }
  }).catch(() => {
    // eslint-disable-next-line react/jsx-boolean-value
    root.render(wrapWithProvider(<LoginPage disabled={true} />));
  });
});
