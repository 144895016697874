/* globals window, PRODUCTION_BUILD */
const loginUrl = () => {
  const {
    pathname,
    search,
    hash,
    hostname,
  } = window.location;
  const currentUrl = encodeURIComponent(
    `${pathname}${search}${hash}`,
  );
  if (!PRODUCTION_BUILD) {
    return 'http://localhost:8080/cas/login?service=http://localhost:8080/uPortal/Login';
  }
  const hostParts = hostname.split('.');
  if (hostParts[0] === 'dev' || hostParts[0] === 'test') {
    return `https://www-${hostParts[0]}.ease.ed.ac.uk/cosign.cgi?cosign-eucsCosign${hostParts[0]}-${hostname}&https://${hostname}/uPortal/Login?refUrl=${currentUrl}`;
  }
  return `https://www.ease.ed.ac.uk/cosign.cgi?cosign-eucsCosign-${hostname}&https://${hostname}/uPortal/Login?refUrl=${currentUrl}`;
};

export default loginUrl();
