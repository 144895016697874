import { React, PropTypes } from 'myedDependenciesReact';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import LoginSection from './LoginSection';

function LoginPage(props) {
  const { disabled } = props;

  return (
    <>
      <Header
        role="banner"
        exceptionOccured
      >
        <div id="region-header-bottom-myed">
          <div className="row">
            <div className="col-sm-12" />
          </div>
        </div>
      </Header>
      <LoginSection disabled={disabled} />
      <Footer />
    </>
  );
}

LoginPage.propTypes = {
  disabled: PropTypes.bool,
};

LoginPage.defaultProps = {
  disabled: false,
};

export default LoginPage;
