import { React, Navigate, useLocation } from 'myedDependenciesReact';

function TmpSkipLink() {
  const { search, pathname } = useLocation();

  // If this is uportalPageBody, redirect to homepage(!) to reinstate old broken behaviour
  if (pathname.match('portalPageBody$')) {
    const to = {
      pathname: '/',
      search,
    };

    return <Navigate replace to={to} />;
  }

  return null;
}

export default TmpSkipLink;
