import { React, PropTypes } from 'myedDependenciesReact';
import EyebrowContainer from './EyebrowContainer';
import NotSupportedPortlet from '../portlets/NotSupportedPortlet';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';
import './Header.scss';

const propTypes = {
  regions: PropTypes.shape({
    'hidden-top': PropTypes.shape({}),
    'page-top': PropTypes.shape({}),
    'detached-banner': PropTypes.shape({}),
    'header-top': PropTypes.shape({}),
    'header-left': PropTypes.shape({}),
    'header-right': PropTypes.shape({}),
    'header-bottom': PropTypes.shape({}),
  }),
  children: PropTypes.shape({}),
  exceptionOccured: PropTypes.bool,
};

const defaultProps = {
  regions: {
    'hidden-top': undefined,
    'page-top': undefined,
    'detached-banner': undefined,
    'header-top': undefined,
    'header-left': undefined,
    'header-right': undefined,
    'header-bottom': undefined,
  },
  children: {},
  exceptionOccured: false,
};

// react-router work around, if pure component navigation does not update
// eslint-disable-next-line react/prefer-stateless-function
class Header extends React.Component {
  render() {
    const {
      regions,
      children,
      exceptionOccured,
    } = this.props;
    return (
      <header className="portal-header" role="banner">
        <NotSupportedPortlet config={regions['hidden-top']} />
        <NotSupportedPortlet config={regions['page-top']} />
        <NotSupportedPortlet config={regions['detached-banner']} />
        <EyebrowContainer exceptionOccured={exceptionOccured} />
        <div className="page-head">
          <div className="container-fluid">
            <div className="row">
              <HeaderLeft config={regions['header-left']} />
              <HeaderRight config={regions['header-right']} />
            </div>
            <div className="row">
              <div className="col-md-12">
                <NotSupportedPortlet
                  config={regions['header-bottom']}
                />
              </div>
            </div>
          </div>
        </div>
        {children}
      </header>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
