/* global window */
import { React, PropTypes } from 'myedDependenciesReact';
import './MyEdComponent.scss';

function MyEdComponent(props) {
  const { viewMode, componentId } = props;
  const Authorized = viewMode === 'view'
    ? window[`${componentId}`].ViewAppContainer
    : window[`${componentId}`].EditAppContainer;
  return (
    <Authorized className="react-portlet" componentId={componentId} />
  );
}

MyEdComponent.propTypes = {
  componentId: PropTypes.string.isRequired,
  viewMode: PropTypes.string.isRequired,
};

export default MyEdComponent;
