import { React, PropTypes } from 'myedDependenciesReact';
import { reduxConnect } from 'MyEdDataLayer';

import Eyebrow from './Eyebrow';

const propTypes = {
  exceptionOccured: PropTypes.bool,
  messages: PropTypes.shape({}).isRequired,
  session: PropTypes.shape({}).isRequired,
  uportalBase: PropTypes.string.isRequired,
  config: PropTypes.shape({}).isRequired,
  authenticated: PropTypes.string.isRequired,
};

const defaultProps = {
  exceptionOccured: false,
};

function mapStateToProps(state) {
  const { messages } = state.messaging;
  const { session } = state;
  const { url } = state.configuration.uportal;
  const { eyebrow } = state.layout.sortedRegions;
  const { authenticated } = state.layout.payload;

  return {
    messages,
    session,
    uportalBase: url,
    config: eyebrow,
    authenticated,
  };
}

class EyebrowContainer extends React.Component {
  hasNoCriticalError() {
    const { messages } = this.props;
    const result = Object.keys(messages).find((key) => messages[key].template === 'CriticalMessage');
    return result === undefined;
  }

  render() {
    const {
      exceptionOccured,
      session,
      uportalBase,
      config,
      authenticated,
    } = this.props;
    const hasNoError = this.hasNoCriticalError();
    return (
      <Eyebrow
        config={config}
        authenticated={authenticated}
        uportalBase={uportalBase}
        exceptionOccured={exceptionOccured}
        session={session}
        hasNoError={hasNoError}
      />
    );
  }
}

EyebrowContainer.propTypes = propTypes;
EyebrowContainer.defaultProps = defaultProps;

export default reduxConnect(mapStateToProps, EyebrowContainer);
