import { React, PropTypes } from 'myedDependenciesReact';
import SignedIn from './SignedIn';
import SignedOut from './SignedOut';
import './Eyebrow.scss';

const showSignIn = (session, uportalBase, isLoggedIn, exceptionOccured, hasNoError) => {
  if (!hasNoError) {
    return null;
  }
  if (isLoggedIn && !exceptionOccured) {
    return (
      <SignedIn
        uportalUrl={uportalBase}
        name={session.person.displayName}
        originalName={session.person.originalUsername}
      />
    );
  }
  return <SignedOut />;
};

function Eyebrow(props) {
  const {
    authenticated,
    session,
    uportalBase,
    exceptionOccured,
    hasNoError,
  } = props;
  const isLoggedIn = authenticated === 'true' && session.person !== undefined;
  return (
    <div id="region-eyebrow" className="container-fluid">
      <div className="row">
        <div className="col-sm-4 hidden-xs">
          <h1>MyEd Student and Staff Portal</h1>
        </div>
        <div className="col-sm-8 col-xs-12  text-right">
          {showSignIn(session, uportalBase, isLoggedIn, exceptionOccured, hasNoError)}
        </div>
      </div>
    </div>
  );
}

Eyebrow.propTypes = {
  authenticated: PropTypes.string.isRequired,
  session: PropTypes.shape({
    person: PropTypes.shape({
      displayName: PropTypes.string,
      originalUsername: PropTypes.string,
    }),
  }).isRequired,
  uportalBase: PropTypes.string.isRequired,
  exceptionOccured: PropTypes.bool.isRequired,
  hasNoError: PropTypes.bool.isRequired,
};

export default Eyebrow;
