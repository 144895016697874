/* globals document */
export default class ScriptElementBuilder {
  static initialise(path) {
    return new ScriptElementBuilder(path);
  }

  constructor(path) {
    this.path = path;
    this.scriptElement = document.createElement('script');
  }

  addAttribute(key, value) {
    this.scriptElement.setAttribute(key, value);
    return this;
  }

  isAsync() {
    this.scriptElement.async = true;
    return this;
  }

  onLoad(load) {
    this.scriptElement.onload = () => {
      load();
    };
    return this;
  }

  onError(error) {
    this.scriptElement.onerror = () => {
      error();
    };
    return this;
  }

  build(parentElement) {
    this.scriptElement.src = this.path;
    parentElement.appendChild(this.scriptElement);
  }
}
