import {
  React,
  PropTypes,
  useParams,
} from 'myedDependenciesReact';
import { reduxConnect } from 'MyEdDataLayer';

import CmsFullPage from './CmsFullPage';

const propTypes = {
  layout: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
  const { layout } = state;

  return {
    layout,
  };
}

function CmsFullPageContainer({ layout }) {
  const match = useParams();
  const {
    layoutLocation,
    contentLocation,
    portletLocation,
    id,
  } = match;
  return (
    <CmsFullPage
      layout={layout}
      layoutLocation={layoutLocation}
      contentLocation={contentLocation}
      portletLocation={portletLocation}
      id={id}
    />
  );
}

CmsFullPageContainer.propTypes = propTypes;

export default reduxConnect(mapStateToProps, CmsFullPageContainer);
