import { React, PropTypes } from 'myedDependenciesReact';

const propTypes = {
  payload: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  hasFocus: PropTypes.bool,
};

const defaultProps = {
  hasFocus: true,
};

class PortletHeader extends React.Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.headerElement = null;
  }

  /**
   * Ensure that if there is a portlet link to focus, we set focus to that element
   */
  componentDidMount() {
    const { hasFocus } = this.props;
    this.headerElement = this._ref.current;
    if (this.headerElement !== null
        && hasFocus === true) {
      this.headerElement.focus();
    }
  }

  render() {
    const { payload, isAdmin, children } = this.props;
    if (payload !== undefined
      && payload.name !== 'customize'
      && !isAdmin) {
      return (
        <div className="panel-heading">
          <h2
            className="panel-title"
            ref={this._ref}
          >
            {payload.name}
          </h2>
          {children}
        </div>
      );
    }
    return null;
  }
}

PortletHeader.propTypes = propTypes;
PortletHeader.defaultProps = defaultProps;

export default PortletHeader;
