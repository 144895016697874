import { React } from 'myedDependenciesReact';
import { useSelector } from 'MyEdDataLayer';
import LoginPage from './LoginPage';
import App from './App';

function AppContainer() {
  const layoutEndpoint = useSelector((state) => state.configuration.uportal.endpoints.layout);
  const layout = useSelector((state) => state.layout);
  const pending = useSelector((state) => state.userDetails.pending);

  if (layoutEndpoint === null || layout.payload === undefined) {
    return null;
  }
  if (layout.payload.authenticated === 'false') {
    return <LoginPage />;
  }

  return <App layout={layout} pending={pending} />;
}

export default AppContainer;
