/* globals window */
import {
  backbone,
  jQuery,
  jQueryMigrate,
  jQueryUi,
  underscore,
  vue,
} from 'myedDependenciesDynamicImports';

class UportalLibrariesLoader {
  constructor() {
    this.resolves = [];
    this.rejects = [];
    this.legacyCodeState = {
      LOADING: 'LOADING',
      LOADED: 'LOADED',
      FAILED: 'FAILED',
    };
  }

  resolveAll() {
    window.LegacyCodeLoadedState = this.legacyCodeState.LOADED;
    this.resolves.forEach((resolve) => {
      resolve();
    });
    this.resolves = null;
    this.rejects = null;
  }

  rejectAll() {
    window.LegacyCodeLoadedState = this.legacyCodeState.FAILED;
    this.rejects.forEach((reject) => {
      reject();
    });
    this.resolves = null;
    this.rejects = null;
  }

  async _loadLibraries() {
    const up = {};
    window.up = up;

    try {
      const [jquery, _] = await Promise.all([
        jQuery(),
        underscore(),
      ]);

      window.jQuery = jquery;
      window.$ = jquery;
      jquery.migrateMute = true;
      await jQueryMigrate();

      const [Backbone] = await Promise.all([
        backbone(),
        jQueryUi(),
      ]);

      up.jQuery = jquery.noConflict(true);
      up._ = _.noConflict ? _.noConflict() : _;
      up._.templateSettings = {
        interpolate: /{{=(.+?)}}/g,
        evaluate: /{{(.+?)}}/g,
        escape: /{{-([\s\S]+?)}}/g,
      };
      up.Backbone = Backbone.noConflict();

      window.Vue = await vue();

      this.resolveAll();
    } catch (err) {
      this.rejectAll();
    }
  }

  load() {
    return new Promise((resolve, reject) => {
      if (window.LegacyCodeLoadedState !== undefined) {
        switch (window.LegacyCodeLoadedState) {
          case this.legacyCodeState.LOADED:
            resolve();
            break;
          case this.legacyCodeState.FAILED:
            reject();
            break;
          default:
            this.resolves.push(resolve);
            this.rejects.push(reject);
            break;
        }
      } else {
        window.LegacyCodeLoadedState = this.legacyCodeState.LOADING;
        this.resolves.push(resolve);
        this.rejects.push(reject);
        this._loadLibraries();
      }
    });
  }
}

export default new UportalLibrariesLoader();
