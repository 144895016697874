import { React, PropTypes } from 'myedDependenciesReact';
import renderColumns from './renderColumns';
import './BasicTemplate.scss';

function BasicTemplate(props) {
  const { content } = props;
  return (
    <section
      className="mainContent"
      role="main"
      aria-label={content.name}
      tabIndex="-1"
    >
      <div className="page container-fluid content">
        <div className="row">
          {renderColumns(content)}
        </div>
      </div>
    </section>
  );
}

BasicTemplate.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

BasicTemplate.defaultProps = {
};

export default BasicTemplate;
