import { React } from 'myedDependenciesReact';
import './Spinner.scss';

function Spinner() {
  return (
    <div className="container-myed-spinner" role="status">
      <span className="myed-spinner" />
      {' '}
      Loading...
    </div>
  );
}

export default Spinner;
