import { React, PropTypes } from 'myedDependenciesReact';
import BasicTemplate from './BasicTemplate';
import MainPageTemplate from './MainPageTemplate';
import './Template.scss';
import NoDropdownTemplate from './NoDropdownTemplate';

function Template(props) {
  const { content } = props;
  let component;
  if (content.myedTemplate === undefined) {
    component = <BasicTemplate content={content} />;
  } else {
    switch (content.myedTemplate) {
      case 'home':
        component = <MainPageTemplate content={content} />;
        break;
      case 'noDropdown':
        component = <NoDropdownTemplate content={content} />;
        break;
      default:
        component = <BasicTemplate content={content} />;
    }
  }
  return (
    <div>
      {component}
    </div>
  );
}

Template.propTypes = {
  content: PropTypes.shape({
    externalId: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    myedTemplate: PropTypes.string,
  }).isRequired,
};

export default Template;
