import {
  React,
  PropTypes,
} from 'myedDependenciesReact';
import { Analytics, ReportAnalytics } from 'MyEdGoogleAnalyticsMyEdProgressive';
import {
  LinkGenerator,
  FavouriteButtonContainer,
} from 'MyEdUtilsCommon';
import './SearchPage.scss';
import changeDocumentTitle from '../../utils/changeDocumentTitle';
import Spinner from '../common/Spinner';

const propTypes = {
  search: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      fname: PropTypes.string,
    })),
    query: PropTypes.string.isRequired,
  }).isRequired,
  query: PropTypes.string,
};

const defaultProps = {
  query: '',
};

class SearchPage extends React.Component {
  async componentDidMount() {
    ReportAnalytics.reportDynamicPageView();
  }

  render() {
    const { search, query } = this.props;
    const { results } = search;
    changeDocumentTitle('Search');

    let resultSummary = <Spinner />;
    let resultList = null;
    if (search.query === query) {
      resultSummary = (
        <div className="myed-search-results">
          <p className="lead">Found {results.length} results from MyEd.</p>
        </div>
      );
      resultList = results.map((portlet) => {
        const link = LinkGenerator.convertUrlToProgressive(portlet.url);
        return (
          <div key={portlet.fname} className="search">
            <div className="search-body">
              <div className="search-inline-header">
                <h2 className="search-title">
                  <Analytics category="Search">
                    <a href={link}>
                      {portlet.title}
                    </a>
                  </Analytics>
                </h2>
                <FavouriteButtonContainer fname={portlet.fname} />
              </div>
              <div className="search-ref">{link}</div>
              <p>
                {portlet.description}
              </p>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="page container-fluid myed-page-content">
        <div className="row">
          <div className="col-md-12">
            <h1 itemProp="headline" className="page-header">Search results</h1>
            {resultSummary}
            {resultList}
          </div>
        </div>
      </div>
    );
  }
}

SearchPage.propTypes = propTypes;
SearchPage.defaultProps = defaultProps;

export default SearchPage;
