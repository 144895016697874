import { React } from 'myedDependenciesReact';
import { SearchFieldContainer } from 'MyEdUtilsCommon';

function HeaderRight() {
  return (
    <div className="col-sm-5 col-md-4">
      <SearchFieldContainer />
    </div>
  );
}

export default HeaderRight;
