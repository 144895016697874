import { React, PropTypes } from 'myedDependenciesReact';
import { FavouriteButtonContainer } from 'MyEdUtilsCommon';
import PortletHeader from './PortletHeader';

function PortletFrame(props) {
  const {
    payload,
    isAdmin,
    children,
    hasFocus,
  } = props;

  const klass = isAdmin
    ? 'panel panel-uoe-low progressive-portlet admin'
    : 'panel panel-uoe-low progressive-portlet';
  if (
    children !== null
    && (payload !== undefined
    && payload.name !== 'customize')) {
    return (
      <div className={klass}>
        <PortletHeader
          payload={payload}
          isAdmin={isAdmin}
          hasFocus={hasFocus}
        >
          <FavouriteButtonContainer id={payload.content[0].chanID} />
        </PortletHeader>
        <div className="panel-body">
          {children}
        </div>
      </div>
    );
  }
  return null;
}

PortletFrame.propTypes = {
  payload: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({
      chanID: PropTypes.string,
    })),
    name: PropTypes.string,
  }),
  isAdmin: PropTypes.bool,
  children: PropTypes.element,
  hasFocus: PropTypes.bool,
};

PortletFrame.defaultProps = {
  isAdmin: false,
  payload: {
    content: [],
    name: '',
  },
  children: null,
  hasFocus: true,
};

export default PortletFrame;
