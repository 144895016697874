import { React, PropTypes } from 'myedDependenciesReact';
import { Analytics } from 'MyEdGoogleAnalyticsMyEdProgressive';
import { NotificationIconContainer, FavouriteIcon } from 'MyEdUtilsCommon';
import './SignedIn.scss';

function usernameText(name, originalName) {
  if (originalName !== undefined) {
    return (
      <div className="user-name">
        <span className="glyphicon glyphicon-user" aria-hidden="true" />
        <span className="sr-only">You are signed in as&nbsp;</span>
        {originalName} is impersonating {name}&nbsp;
      </div>
    );
  }
  return (
    <div className="user-name">
      <span className="glyphicon glyphicon-user" aria-hidden="true" />
      <span className="sr-only">You are signed in as&nbsp;</span>
      {name} &nbsp;
    </div>
  );
}

function SignedIn(props) {
  const { uportalUrl, name, originalName } = props;
  const logout = `${uportalUrl}/uPortal/Logout`;
  return (
    <div>
      <FavouriteIcon />
      <NotificationIconContainer />
      {usernameText(name, originalName)}
      <div className="sign-out">
        <Analytics category="Navigation" action="page-bar-click">
          <a
            href={logout}
            title="Log off and exit the portal"
          >
            <span
              className="glyphicon glyphicon-log-out"
              aria-hidden="true"
            />
            {' '}
            Sign Out
          </a>
        </Analytics>
      </div>
    </div>
  );
}

SignedIn.propTypes = {
  name: PropTypes.string.isRequired,
  originalName: PropTypes.string,
  uportalUrl: PropTypes.string.isRequired,
};

SignedIn.defaultProps = {
  originalName: undefined,
};

export default SignedIn;
