import { React, PropTypes } from 'myedDependenciesReact';
import './BasicTemplate.scss';
import BasicTemplate from './BasicTemplate';

/*
  Wraps BasicTemplate ensures dropDown does not get shown
 */
function NoDropdownTemplate(props) {
  const { content } = props;
  return (
    <BasicTemplate content={content} />
  );
}

NoDropdownTemplate.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

NoDropdownTemplate.defaultProps = {
};

export default NoDropdownTemplate;
