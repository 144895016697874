/* globals document, window */
import { React, PropTypes } from 'myedDependenciesReact';
import { reduxConnect } from 'MyEdDataLayer';
import MyEdComponent from './MyEdComponent';
import VueComponent from './VueComponent';
import AngularComponent from './AngularComponent';
import CmsPortlet from './CmsPortlet';
import PortletFrame from './PortletFrame';
import ScriptElementBuilder from '../../utils/ScriptElementBuilder';
import './Portlet.scss';

const propTypes = {
  payload: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({
      typeID: PropTypes.string,
      lifecycleState: PropTypes.string,
      ID: PropTypes.string,
      parameters: PropTypes.shape({
        'data-component': PropTypes.string,
        'data-component-id': PropTypes.string,
        'data-component-src': PropTypes.string,
      }),
      url: PropTypes.string,
    })),
    name: PropTypes.string,
  }),
  isAdmin: PropTypes.bool,
  showMenu: PropTypes.bool,
  hasFrame: PropTypes.bool,
  hasFocus: PropTypes.bool,
  children: PropTypes.element,
  uportalBase: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { url } = state.configuration.uportal;

  return {
    uportalBase: url,
  };
}

const defaultProps = {
  isAdmin: false,
  showMenu: false,
  payload: {
    content: [],
    name: '',
  },
  hasFrame: true,
  hasFocus: false,
  children: undefined,
};

class Portlet extends React.Component {
  static _loadScript(componentSrc, componentId) {
    if (document.querySelector(`*[data-uportal-id='${componentId}']`) === null) {
      return new Promise(((resolve, reject) => {
        ScriptElementBuilder.initialise(componentSrc)
          .addAttribute('data-uportal-id', componentId)
          .isAsync()
          .onLoad(resolve)
          .onError(reject)
          .build(document.body);
      }));
    }
    return Promise.resolve();
  }

  constructor(props) {
    super(props);
    this.state = {
      componentType: null,
      componentName: null,
      viewUrl: null,
      viewMode: 'view',
    };
  }

  componentDidMount() {
    const { payload, uportalBase } = this.props;
    if (payload.content.length > 0 && payload.content[0].typeID !== '-1' && payload.content[0].lifecycleState !== 'EXPIRED') {
      const content = payload.content[0];
      const { lifecycleState } = content;
      const componentId = content.ID;
      const params = content.parameters || {};
      const componentType = params['data-component'];
      const componentName = params['data-component-id'];
      const componentSrc = params['data-component-src'];

      if (componentType !== undefined && lifecycleState !== 'MAINTENANCE') {
        Portlet._loadScript(`${uportalBase}${componentSrc}`, componentId).then(() => {
          // TODO: Timeout needed to allow for UMD module to load in global scope
          setTimeout(() => {
            if (window[`${componentName}`] !== undefined) {
              this.setState({
                componentType,
                componentName,
              });
            } else {
              this.setState({
                componentType: 'legacy',
                viewUrl: payload.content[0].url,
                id: componentId,
              });
            }
          }, 50);
        }).catch(() => {
          this.setState({
            componentType: 'legacy',
            viewUrl: payload.content[0].url,
            id: componentId,
          });
        });
      } else {
        this.setState({
          componentType: 'legacy',
          viewUrl: payload.content[0].url,
          id: componentId,
        });
      }
    } else {
      this.setState({
        componentType: null,
      });
    }
  }

  _updateViewMode = (mode) => {
    this.setState({
      viewMode: mode,
    });
  };

  _renderPortlet() {
    const {
      viewMode,
      componentType,
      componentName,
      viewUrl,
      id,
    } = this.state;
    const {
      payload,
    } = this.props;
    let component = null;
    if (componentType !== null) {
      switch (componentType) {
        case 'legacy':
          component = (
            <CmsPortlet
              url={viewUrl}
              id={id}
              name={payload.name}
            />
          );
          break;
        case 'react':
          component = (
            <MyEdComponent
              componentId={componentName}
              viewMode={viewMode}
            />
          );
          break;
        case 'vue':
          component = (
            <VueComponent
              componentId={componentName}
              viewMode={viewMode}
            />
          );
          break;
        case 'angular':
          component = (
            <AngularComponent
              componentId={componentName}
              viewMode={viewMode}
            />
          );
          break;
        default:
          component = null;
      }
    }
    return component;
  }

  render() {
    const {
      payload, isAdmin, showMenu, children, hasFrame, hasFocus,
    } = this.props;
    const {
      componentType,
      viewMode,
    } = this.state;

    if (hasFrame === true) {
      return (
        <PortletFrame
          payload={payload}
          isAdmin={isAdmin}
          showMenu={showMenu}
          componentType={componentType}
          viewMode={viewMode}
          hasFocus={hasFocus}
          updateViewMode={this._updateViewMode}
        >
          {this._renderPortlet()}
        </PortletFrame>
      );
    }

    if (
      children !== null
      && (payload !== undefined
      && payload.name !== 'customize')) {
      return (
        this._renderPortlet()
      );
    }

    return null;
  }
}

Portlet.propTypes = propTypes;
Portlet.defaultProps = defaultProps;

export default reduxConnect(mapStateToProps, Portlet);
