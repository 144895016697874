import { React, PropTypes } from 'myedDependenciesReact';
import { MessageCenterContainer } from 'MyEdUtilsCommon';
import './BasicTemplate.scss';
import './MainPageTemplate.scss';
import EditablePortlet from '../../portlets/EditablePortlet';
import changeDocumentTitle from '../../../utils/changeDocumentTitle';

const propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
    ID: PropTypes.string,
    externalId: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      ID: PropTypes.string,
    })),
  }).isRequired,
};

class MainPageTemplate extends React.Component {
  renderColumn(column) {
    const { content } = this.props;
    const externalId = content.externalId !== undefined ? `${content.externalId}` : `${content.ID}`;
    const isAdmin = externalId === 'admin';

    return (
      <div className="col-md-12">
        {column.content.map((portlet) => {
          const config = {
            content: [portlet],
            name: portlet.name,
          };
          return (
            <EditablePortlet
              key={portlet.ID}
              payload={config}
              isAdmin={isAdmin}
              componentId={portlet.chanID}
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { content } = this.props;
    changeDocumentTitle('Home');
    return (
      <section
        className="mainContent"
        role="main"
        aria-label={content.name}
        tabIndex="-1"
      >
        <div className="page container-fluid myed-page-content">
          <MessageCenterContainer />
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {this.renderColumn(content.content[0])}
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                {this.renderColumn(content.content[1])}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

MainPageTemplate.propTypes = propTypes;

export default MainPageTemplate;
