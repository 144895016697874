import { React, PropTypes } from 'myedDependenciesReact';
import { Analytics } from 'MyEdGoogleAnalyticsMyEdProgressive';
import { MessageCenterContainer } from 'MyEdUtilsCommon';
import loginUrl from './utils/loginUrl';

/* eslint-disable react/jsx-boolean-value */
function LoginSection(props) {
  const { disabled } = props;
  return (
    <section className="mainContent" role="main" aria-label="Login" tabIndex="-1">
      <div className="page container content">
        <MessageCenterContainer componentID="LOGIN" />
        <div className="row">
          <div className="col-md-12">
            <section aria-label="Renders a login page for MyEd">
              <div className="panel panel-uoe-low progressive-portlet">
                <div className="panel-heading">
                  <h2 className="panel-title">
                    MyEd Login
                  </h2>
                </div>
                <div className="panel-body">
                  <div id="component-u18l1n1110">
                    <div>
                      <div id="alertContent" />
                      <div className="region region-content">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                            <p>
                              MyEd, the University&apos;s web portal, is available to applicants,
                              students, staff and visitors.
                              You can login to MyEd using your University Login.
                            </p>
                            <div id="loginLinks">
                              <p>
                                {disabled ? '' : (
                                  <Analytics category="MyEdProgressive">
                                    <a
                                      href={loginUrl}
                                      data-role="button"
                                      className="btn btn-primary btn-lg"
                                      id="login-btn"
                                    >
                                      <span className="glyphicon glyphicon-log-in" />
                                      Login to MyEd
                                    </a>
                                  </Analytics>
                                )}
                              </p>
                              <p>
                                <a
                                  href="https://www.ease.ed.ac.uk/register/?url=https://www.myed.ed.ac.uk/uPortal/"
                                  id="register"
                                >
                                  Set up your University Login
                                </a>
                              </p>
                            </div>
                            <div id="noLoginLinks" className="hidden">
                              <p>
                                MyEd is currently unavailable - we apologise for any inconvenience.
                              </p>
                              <p>
                                For further details review the message above.
                              </p>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                            <div className="panel panel-uoe-low">
                              <div className="panel-heading">
                                <h3 className="panel-title">About</h3>
                              </div>
                              <div className="panel-body">
                                <p>
                                  <a
                                    href="http://www.ed.ac.uk/information-services/computing/comms-and-collab/myed-portal"
                                  >
                                    About MyEd
                                  </a>
                                </p>
                                <p>
                                  <a
                                    href="http://www.ed.ac.uk/information-services/computing/comms-and-collab/myed-portal/myed-help"
                                  >
                                    MyEd Help
                                  </a>
                                </p>
                                <p>
                                  <a
                                    href=" https://www.ed.ac.uk/information-services/computing/computing-infrastructure/authentication-authorisation/your-university-login"
                                  >
                                    About your University Login
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}
/* eslint-enable react/jsx-boolean-value */

LoginSection.propTypes = {
  disabled: PropTypes.bool,
};

LoginSection.defaultProps = {
  disabled: false,
};

export default LoginSection;
