import { React, PropTypes } from 'myedDependenciesReact';
import EditablePortlet from '../../portlets/EditablePortlet';

const propTypes = {
  content: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      ID: PropTypes.string,
    })),
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  settings: PropTypes.shape({}),
  width: PropTypes.number.isRequired,
};

const defaultProps = {
  settings: undefined,
};

class Column extends React.Component {
  _getWidth() {
    const { isAdmin, width } = this.props;
    const bootstrapWidth = 12;
    const aWidth = isAdmin ? 100 : width;
    const newWidth = Math.round((aWidth / 100) * bootstrapWidth);
    return `col-md-${newWidth}`;
  }

  render() {
    const { content, isAdmin, settings } = this.props;
    if (content.content !== undefined) {
      return (
        <div className={this._getWidth()}>
          {content.content.map((portlet) => {
            const config = {
              content: [portlet],
              name: portlet.name,
            };
            return (
              <EditablePortlet
                key={portlet.ID}
                payload={config}
                isAdmin={isAdmin}
                settings={settings}
                componentId={portlet.chanID}
              />
            );
          })}
        </div>
      );
    }
    return null;
  }
}

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;

export default Column;
