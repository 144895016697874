import { React } from 'myedDependenciesReact';
import { Analytics } from 'MyEdGoogleAnalyticsMyEdProgressive';
import img from '@uoe/edgel/dist/images/logo.png';

function HeaderLeft() {
  return (
    <div id="region-header-left" className="col-sm-7 col-md-8">
      <Analytics category="MyEdProgressive">
        <a href="https://www.ed.ac.uk" title="The University of Edinburgh home">
          <img
            className="uoe-logo"
            src={img}
            alt="The University of Edinburgh home"
          />
        </a>
      </Analytics>
    </div>
  );
}

export default HeaderLeft;
