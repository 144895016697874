import { React } from 'myedDependenciesReact';
import './Footer.scss';

function Footer() {
  return (
    <footer role="contentinfo">

      <div className="container-fluid legal-footer">
        <div className="myed-privacy">
          <a
            href="https://www.ed.ac.uk/information-services/computing/comms-and-collab/myed-portal/privacy-and-cookies"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy &amp; Cookies
          </a>
        </div>
        <div className="myed-accessibility">
          <a
            href="https://www.ed.ac.uk/information-services/computing/comms-and-collab/myed-portal/myed-accessibility"
            rel="noopener noreferrer"
            target="_blank"
          >
            Web Accessibility
          </a>
        </div>
      </div>

      <div className="container-fluid container-footer-copyright">
        <div className="copyright">
          <p>
            Unless explicitly stated otherwise,
            all material is copyright &copy; The University of Edinburgh.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
