import { React } from 'myedDependenciesReact';
import Column from './Column';

function renderColumns(content) {
  const externalId = content.externalId !== undefined ? `${content.externalId}` : `${content.ID}`;
  const isAdmin = externalId === 'admin';
  const columns = [];
  if (content.content !== undefined) {
    const numColumns = content.content.length;
    const awidth = (100 / numColumns);
    content.content.forEach((column) => {
      columns.push(
        <Column
          key={column.ID}
          content={column}
          isAdmin={isAdmin}
          width={awidth}
        />,
      );
    });
  }
  return columns;
}

export default renderColumns;
