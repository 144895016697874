/* global PRODUCTION_BUILD */
import { React, PropTypes } from 'myedDependenciesReact';

const propTypes = {
  config: PropTypes.shape({
    name: PropTypes.string,
  }),
};

const defaultProps = {
  config: {},
};

class NotSupportedPortlet extends React.PureComponent {
  render() {
    const { config } = this.props;
    if (config.name !== undefined && !PRODUCTION_BUILD) {
      console.warn(`${config.name} - Not Supported`);
    }
    return null;
  }
}

NotSupportedPortlet.propTypes = propTypes;
NotSupportedPortlet.defaultProps = defaultProps;

export default NotSupportedPortlet;
