/* global window */
import { React, PropTypes } from 'myedDependenciesReact';
import './MyEdComponent.scss';

const propTypes = {
  componentId: PropTypes.string.isRequired,
  viewMode: PropTypes.string.isRequired,
};

class VueComponent extends React.Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  componentDidMount() {
    if (this._ref.current) {
      const { componentId, viewMode } = this.props;
      this.element = this._ref.current;
      window[`${componentId}`].render(
        this.element,
        viewMode,
      );
    }
  }

  componentWillUnmount() {
    delete this.element;
  }

  render() {
    return <div ref={this._ref} />;
  }
}

VueComponent.propTypes = propTypes;

export default VueComponent;
