import { React, PropTypes } from 'myedDependenciesReact';
import { PortletErrorBoundary } from 'MyEdUtilsCommon';
import './Navigation.scss';
import EditablePortlet from '../portlets/EditablePortlet';

const propTypes = {
  fname: PropTypes.string.isRequired,
  hasFrame: PropTypes.bool,
  navigationRegion: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

const defaultProps = {
  hasFrame: true,
};

class Channel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      portlet: null,
    };
  }

  componentDidMount() {
    const { navigationRegion, fname } = this.props;
    if (navigationRegion !== undefined) {
      const portlet = navigationRegion.content.find((content) => content.fname === fname);
      if (portlet !== undefined) {
        this.setState({
          portlet,
        });
      }
    }
  }

  render() {
    const { portlet } = this.state;
    if (portlet !== null) {
      const config = {
        content: [portlet],
        name: portlet.name,
      };
      const { hasFrame } = this.props;
      return (
        <PortletErrorBoundary key={portlet.ID} payload={config} componentId={portlet.chanID}>
          <EditablePortlet
            key={portlet.ID}
            payload={config}
            isAdmin={false}
            hasFrame={hasFrame}
            componentId={portlet.chanID}
          />
        </PortletErrorBoundary>
      );
    }
    return null;
  }
}

Channel.propTypes = propTypes;
Channel.defaultProps = defaultProps;

export default Channel;
