import {
  React,
  Route,
  Routes,
  PropTypes,
} from 'myedDependenciesReact';
import Template from '../body/templates/Template';
import CmsFullPageContainer from '../body/CmsFullPageContainer';
import SearchPageContainer from '../body/SearchPageContainer';
import LoginSection from '../../LoginSection';
import TmpSkipLink from './TmpSkipLink';

const propTypes = {
  navigation: PropTypes.shape({
    tabs: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  authenticated: PropTypes.string.isRequired,
};

class RouterView extends React.Component {
  static _mainRoute(tab, key) {
    return (
      <Route
        key={key.toString()}
        exact
        path="/"
        element={<Template content={tab} />}
      />
    );
  }

  static _renderRoute(tab, key) {
    const slug = tab.externalId !== undefined ? `/${tab.externalId}` : `/${tab.ID}`;
    return (
      <Route
        key={key.toString()}
        path={slug}
        element={<Template content={tab} />}
      />
    );
  }

  static _renderCmsFullPage(key) {
    return (
      <Route
        key={key.toString()}
        path="/:activeUrl/cms/:layoutLocation/:contentLocation/:portletLocation/:id"
        element={<CmsFullPageContainer />}
      />
    );
  }

  static _searchPage(key) {
    return (
      <Route
        key={key.toString()}
        path="/search"
        element={<SearchPageContainer />}
      />
    );
  }

  static _defaultRoute(tab, key) {
    return (
      <Route
        key={key.toString()}
        path="*"
        element={<Template content={tab} />}
      />
    );
  }

  _generateRoutes() {
    const routerRoutes = [];
    const { navigation, authenticated } = this.props;
    if (authenticated === 'true') {
      routerRoutes.push(RouterView._renderCmsFullPage(0));
      routerRoutes.push(RouterView._searchPage(1));
      routerRoutes.push(RouterView._mainRoute(navigation.tabs[0], 2));
      navigation.tabs.forEach((tab, index) => {
        routerRoutes.push(RouterView._renderRoute(navigation.tabs[0], (index + 3)));
      });
      routerRoutes.push(RouterView._defaultRoute(navigation.tabs[0], (navigation.tabs.length + 4)));
    } else {
      routerRoutes.push(<Route
        key="login-section-key"
        exact
        path="*"
        element={<LoginSection />}
      />);
    }
    return routerRoutes;
  }

  render() {
    const renderRoutes = this._generateRoutes();
    return (
      <>
        <TmpSkipLink />
        <Routes location={null}>
          {renderRoutes}
        </Routes>
      </>
    );
  }
}

RouterView.propTypes = propTypes;

export default RouterView;
