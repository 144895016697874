import { React } from 'myedDependenciesReact';
import { Analytics } from 'MyEdGoogleAnalyticsMyEdProgressive';
import loginUrl from '../../utils/loginUrl';
import './SignedOut.scss';

function SignedOut() {
  return (
    <div className="sign-in">
      <Analytics category="MyEdProgressive" action="sign-in">
        <a
          href={loginUrl}
          title="Login with EASE"
        >
          <span
            className="glyphicon glyphicon-log-in"
            aria-hidden="true"
          />
          {' '}
          Sign In
        </a>
      </Analytics>
    </div>
  );
}

export default SignedOut;
